/*
 * @Descripttion:
 * @version:
 * @Author: zhaoxiang
 * @Date: 2020-02-19 14:00:47
 * @LastEditors: zhaoxiang
 * @LastEditTime: 2020-02-28 15:57:42
 */
import './set-public-path';
import Vue from 'vue';
import Vuex from 'vuex';
import AvocadoLoadVue from 'avocado-load-vue';
import axios from 'axios';
import packageConfig from '../package.json';
import HttpUtil from './utils/http/http-util-promise';
import router from './router';
import store from './store';
import permissionButton from './components/permission-button';
import datePicker from './components/date-picker'
import App from './App.vue';
import has from 'lodash/has';
import forEach from 'lodash/forEach';
import vNum from './directive/v-num'
import Navigation from '../vue-navigation/src'
import { getCurretPath } from '../vue-navigation/src/utils'
// // 导入富文本编辑器
// import VueQuillEditor from 'vue-quill-editor';
// // 导入富文本编辑器样式
// import 'quill/dist/quill.core.css';
// import 'quill/dist/quill.snow.css';
// import 'quill/dist/quill.bubble.css';
// import Quill from 'quill';
// import imageResize from 'quill-image-resize-module';
// Quill.register('modules/imageResize', imageResize);
// // 将富文本编辑器，注册为全局可用组件
// Vue.use(VueQuillEditor);
// // import router from './router';
// // import store from './store';

Vue.config.productionTip = false;

// 添加Vuex支持
Vue.use(Vuex);
Vue.use(Navigation, {router,moduleName:'lcproduct'})
Vue.prototype.$http = axios;
Vue.prototype.$ajax = axios;
Vue.prototype.$httpUtil = HttpUtil;

// 添加全局自定义指令
Vue.directive('num', vNum)
// 添加全局组件
Vue.use(permissionButton);
Vue.use(datePicker)
// 设置路由生命周期方法
if (has(Vue.prototype, '$routerHook')) {
  forEach(Vue.prototype.$routerHook, (item, index) => {
    router[index](item);
  });
}
router.beforeEach((to, from, next) => {
  if(getCurretPath()==='lcproduct'){
    setTimeout(() => {
      next()
    }, 0);
  }else{
    next()
  }
})
// 使用Avocado创建生命周期对象
const vueLifecycles = AvocadoLoadVue({
  Vue,
  rootDomId: 'views',
  // 应用节点名称
  appName: packageConfig.name,
  appOptions: {
    render: h => h(App),
    store,
    router,
  },
});

export const { bootstrap } = vueLifecycles;
// export const { mount } = vueLifecycles;
export const { unmount } = vueLifecycles;
export const { update } = vueLifecycles;
export const mount = props => vueLifecycles.mount(props).then(props => {
  // console.log("mount执行！", appCssDom, cssPath);
 console.log('==挂载了produc')
});
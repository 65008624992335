import CurInfo from './routes'
import Navigator from './navigator'
import NavComponent from './components/Navigation'
import { genKey, isObjEqual, getCurretPath } from './utils'

export default {
  install: (Vue, { router, store, moduleName = 'navigation', keyName = 'VNK' } = {}) => {
    if (!router) {
      console.error('vue-navigation need options: router')
      return
    }
    new CurInfo(moduleName)
    console.dir(CurInfo)
    const bus = new Vue()
    const navigator = Navigator(bus, store, moduleName, keyName)

    // hack vue-router replace for replaceFlag
    const routerReplace = router.replace.bind(router)
    let replaceFlag = false
    router.replace = (location, onComplete, onAbort) => {
      replaceFlag = true
      routerReplace(location, onComplete, onAbort)
    }

    // init router`s keyName
    router.beforeEach((to, from, next) => {
      if (!to.query[keyName]) {
        const query = { ...to.query }
        // go to the same route will be set the same key
        if (to.path === from.path && isObjEqual(
          { ...to.query, [keyName]: null },
          { ...from.query, [keyName]: null },
        ) && from.query[keyName]) {
          query[keyName] = from.query[keyName]
        } else {
          query[keyName] = genKey()
        }
        next({ name: to.name, params: to.params, query, replace: replaceFlag || !from.query[keyName] })
      } else {
        next()
      }
    })

    // record router change
    router.afterEach((to, from) => {
      // console.log('====getCurretPath',getCurretPath())
      if(getCurretPath() === moduleName){
        // console.log('===执行了afterEachProduct')
        navigator.record(to, from, replaceFlag)
        replaceFlag = false
      }
    })

    Vue.component('navigation', NavComponent(keyName))

    Vue[`${moduleName}Navigation`] = Vue.prototype[`$${moduleName}Navigation`] = {
      on: (event, callback) => {
        bus.$on(event, callback)
      },
      once: (event, callback) => {
        bus.$once(event, callback)
      },
      off: (event, callback) => {
        bus.$off(event, callback)
      },
      getRoutes: () => CurInfo.Routes.slice(),
      cleanRoutes: () => navigator.reset(),
    }
  }
}

<template>
  <div class="form-warp">
    <el-date-picker
      class="ml5"
      :disabled="editDisable"
      :type="type"
      v-model="value[0]"
      placeholder="开始时间"
      value-format="yyyy-MM-dd HH:mm:ss"
      format="yyyy-MM-dd HH:mm:ss"
      default-time="00:00:00"
      style="width:200px"
      :picker-options="startOptons"
      @change="startChange"
    />
    <div class="middle-text">至</div>
    <el-date-picker
      class
      :disabled="editDisable"
      :type="type"
      v-model="value[1]"
      placeholder="结束时间"
      value-format="yyyy-MM-dd HH:mm:ss"
      format="yyyy-MM-dd HH:mm:ss"
      default-time="23:59:59"
      style="width:200px"
      :picker-options="endOptons"
      @change="endChange"
    />
  </div>
</template>
<script>
import { formatTime, formatTimestamp } from "@/utils/date/dateformat";
const nowDate = formatTimestamp(formatTime({}));


export default {
  name: 'datePicker',
  props: {
    value: {
      type: Array
    },
    editDisable: Boolean,
    type: String, //type:date，
    require: Boolean
  },
  data() {
    return {
      startOptons: {
        disabledDate: time => {
          return time.getTime() < nowDate
        }
      },
      endOptons: {
        disabledDate: time => {
          const begin = formatTimestamp(this.value[0] || '');
          const minDate =
            !this.value[0] ? nowDate : begin;
          return time.getTime() < minDate;
        }
      }
    }
  },
  methods: {
    startChange(e) {
      let begin = formatTimestamp(this.value[0] || '');
      let end = formatTimestamp(this.value[1] || '');
      // 如果选择的开始时间大于结束时间，则自动清空结束时间，重新选择
      if (begin > end) {
        this.$emit('input', [this.value[0], ''])
      }
      this.$emit("on-change", this.value)
    },
    endChange(e) {
      if(this.type === 'date' && e){
       this.$emit('input', [this.value[0], e.replace('00:00:00', '23:59:59')])
      }
      let begin = formatTimestamp(this.value[0] || '');
      let end = formatTimestamp(this.value[1] || '');
       if (begin > end) {
        this.$emit('input', ['', this.value[1]])
      }
      this.$emit("on-change", this.value)
      this.$emit("on-change", this.value)
    },
    checkEmpty() {
      if (this.require) {
        //如果是必填项，判断是否有值
        return !(this.value[0] && this.value[1])
      } else {
        //如果不是必填项，但保证两个时间项都选上
        return  (this.value[0] && !this.value[1]) || (!this.value[0] && this.value[1])
      }
    }
  }
}
</script>
<style scoped>
.form-warp {
  display: flex;
  align-items: center;
}
.middle-text {
  margin: 0 5px;
}
</style>

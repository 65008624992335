/*
 * 日期格式处理函数（时间戳转日期）
 * date        时间戳 默认为当前时间
 * formatStr   须转换的日期格式字符串 默认格式“YYYY-MM-dd” 注：其中M代表月、m代表秒
 * add         处理date时间+-n天的日期 默认为0
 * 例： formatTime({date:1508815202,formatStr:"YYYY-MM-dd",add:1})
 */
export const formatTime = ({ date = new Date(), formatStr = 'YYYY-MM-dd', add = 0 }) => {
  let newdate = (date instanceof Date) ? date : (date.toString().length > 10 ? parseInt(date) : parseInt(date) * 1000);
  newdate = (newdate instanceof Date) ? newdate : new Date(newdate); // 兼容date类型数据
  newdate = newdate.setDate(newdate.getDate() + add); // +-add天时间
  const dd = new Date(newdate);
  const year = dd.getFullYear();
  const month = dd.getMonth() + 1;
  const day = dd.getDate();
  const hour = dd.getHours();
  const minute = dd.getMinutes();
  const second = dd.getSeconds();
  const Week = ['日', '一', '二', '三', '四', '五', '六'];
  let str = formatStr;

  str = str.replace(/yyyy|YYYY/, year);
  str = str.replace(/yy|YY/, formatNumber(dd.getYear()));

  str = str.replace(/MM/, formatNumber(month));
  str = str.replace(/M/g, month);

  str = str.replace(/w|W/g, Week[dd.getDay()]);

  str = str.replace(/dd|DD/, formatNumber(day));
  str = str.replace(/d|D/g, day);

  str = str.replace(/hh|HH/, formatNumber(hour));
  str = str.replace(/h|H/g, hour);
  str = str.replace(/mm/, formatNumber(minute));
  str = str.replace(/m/g, minute);

  str = str.replace(/ss|SS/, formatNumber(second));
  str = str.replace(/s|S/g, second);
  return str;
};
/*
 * 日期转时间戳函数
 * str  日期字符串
 */
export const formatTimestamp = (str) => {
  let date = new Date(Date.parse(str.replace(/-/g, '/')));
  date = date.getTime();
  return date;
};

/*
 *
 *
 */
export const formateDateString = (str) => {
  const dateList = str.split(' ');

  return dateList[0];
};

const formatNumber = (n) => {
  n = n.toString();
  return n[1] ? n : `0${n}`;
};

/*
* 两个日期相差多少天sDate1和sDate2是2002-12-18格式
* */
export const dateDiff = (sDate1, sDate2) => { // sDate1和sDate2是2002-12-18格式
  let aDate; let oDate1; let oDate2; let
    iDays;
  aDate = sDate1.split('-');
  oDate1 = new Date(`${aDate[1]}-${aDate[2]}-${aDate[0]}`); // 转换为12-18-2002格式
  aDate = sDate2.split('-');
  oDate2 = new Date(`${aDate[1]}-${aDate[2]}-${aDate[0]}`);
  iDays = parseInt(Math.abs(oDate1 - oDate2) / 1000 / 60 / 60 / 24); // 把相差的毫秒数转换为天数
  return iDays;
};

// export default {
//   formatTime,
//   formatTimestamp,
//   formateDateString,
//   dateDiff,
// }

<!--
 * @Descripttion:
 * @version:
 * @Author: wangxiaole
 * @Date: 2020-02-19 14:00:54
 * @LastEditors:  
 * @LastEditTime: 2022-07-07 15:57:10
 -->
<template>
  <div id="app">
    <router-view />
  </div>
</template>

<script>
export default {
  name: 'App',
  mounted() {
  },
};
</script>
<style >
.el-date-range-picker__editor .el-input__inner{
    width: 116px !important;
  }
</style>

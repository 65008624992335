// /**
//  * Created by yang.xu on 17/11/13.
//  */
// import Vue from 'vue';
// import axios from 'axios';
// import _ from 'lodash';
// // import moment from 'moment';
// import toast from '../../views/common/toast';
// import loading from '../../views/common/loading';
// import getOptions from '../common/getOptions';
// import User from '../checkAuth';

// const MaxTimeOut = 300000;
// const DownloadFileTimeOut = 60000;

// Vue.use(toast);
// Vue.use(loading);

// // let Toast = {};
// // let Loading = {};
// // Vue.nextTick(() => {
// //   Toast = Vue.prototype.$toast;
// //   Loading = Vue.prototype.$loading;
// // });

// // const operateLoading = (flag, msg) => {
// //   if (flag) {
// //     Loading.show(msg);
// //   } else {
// //     Loading.hide();
// //   }
// // };


// const showToast = (type, text, during) => {
//   // const time = during || 2000;
//   // Toast.show(type, text, time);
// };

// const pmsOptions = getOptions();
// const isPMS = pmsOptions.isPMS ? 1 : 0;
// // 记录当前请求并发数量，解决 单个请求结束后loading消失问题
// let reqCount = 0;

// // Api日志数据
// const apiLogList = [];
// // 添加一个请求拦截器
// axios.interceptors.request.use(
//   (config) => {
//     if (config.url.indexOf('log-monitor.') > -1) {
//       return config;
//     }
//     // if(_.startWith(config.url,'http://log-monitor.rezenhotels.cn')){
//     //   return config;
//     // }
//     apiLogList.push({
//       url: config.url,
//       time: window.$moment().valueOf(),
//     });
//     return config;
//   },
//   error => Promise.reject(error),
// );
// axios.interceptors.response.use(
//   (response) => {
//     // 获取api请求地址
//     const url = _.trim(_.get(response, ['config', 'url'], ''));
//     // 根据url获取请求开始时间
//     const logData = _.find(apiLogList, item => _.trim(item.url) === url);
//     // 如果存在接口调用的开始时间数据，则进行日志处理
//     if (logData && window.logHandle) {
//       window.logHandle.apiLog({
//         url: _.get(response, ['config', 'url'], ''),
//         responseSize: _.toNumber(_.get(response, ['headers', 'content-length'], '')),
//         timeLength: window.$moment().valueOf() - logData.time,
//         currentTime: window.$moment().format('llll'),
//       });
//     }
//     _.remove(apiLogList, item => item.url === url);
//     return response;
//   },
//   (error) => {
//     // 如果接口调用出错，则记录错误信息
//     if (window.logHandle) {
//       // 序列化body数据
//       let body;
//       try {
//         body = JSON.parse(_.get(error, ['config', 'data'], {}));
//       } catch (e) {
//         console.log('error', e);
//       }
//       if (_.get(error, ['config', 'url'], '').indexOf('log-monitor.rezenhotels.cn') === -1) {
//         // 插入错误日志
//         window.logHandle.errorLog({
//           errorType: 'api',
//           url: _.get(error, ['config', 'url'], ''),
//           params: body,
//           status: _.get(error, ['response', 'status'], ''),
//           errMessage: _.get(error, ['response', 'statusText'], ''),
//         });
//       }
//     }
//     return Promise.reject(error);
//   },
// );

// /**
//  * 发起Http请求
//  * @param settings
//  *
//  * 请求默认Post
//  * 超时默认10s
//  *
//  */
// const fetch = (settings) => {
//   const method = settings.method ? settings.method : 'post';
//   const url = settings.url ? settings.url : '';
//   const timeout = settings.timeout ? settings.timeout : MaxTimeOut;

//   // Url请求中参数
//   const params = settings.params ? settings.params : {};

//   // post消息体
//   const data = settings.data ? settings.data : {};

//   // 表单提交
//   // data = qs.stringify(data)

//   const successCallBack = settings.success ? settings.success : null;
//   const errorCallBack = settings.error ? settings.error : null;

//   if (!url) {
//     console.error('url不能为空');
//     return;
//   }

//   // 是否显示Loading
//   let isLoadingPlugin = true;
//   if (settings.isLoadingPlugin === false) {
//     isLoadingPlugin = false;
//   }

//   if (isLoadingPlugin) {
//     // operateLoading(true);
//   }
//   reqCount += 1;
//   const apiurl = _.startsWith(url, '/') ? url : _.pad(url, 0, '/');
//   axios({
//     method,
//     url: `/api${apiurl}`,
//     params,
//     data,
//     timeout,
//     headers: { isPMS },
//   })
//     .then((response) => {
//       reqCount -= 1;
//       if (reqCount === 0 && isLoadingPlugin) {
//         // operateLoading(false);
//       }

//       // 通用处理
//       if (response.data.retcode && response.data.retcode === -41) {
//         // 登录状态异常时，清除cookie重新登陆
//         User.ClearAllCookie();
//         window.location.href = '/#/loginPage';
//       } else if (successCallBack) {
//         try {
//           successCallBack.call(this, response.data);
//         } catch (error) {
//           // showToast("text", "数据处理错误：" + error.message);
//           console.log(`数据处理错误：${error.message}`);
//         }
//       }
//     })
//     .catch((error) => {
//       reqCount = -1;
//       if (reqCount === 0) {
//         // operateLoading(false);
//       }

//       // 通用处理

//       if (errorCallBack) {
//         errorCallBack.call(this, error.message);
//       }
//     });
// };

// /** example */

// // download({
// //   data:{
// //     searchType:"0"
// //   },
// //   path:'/member/data/query/exportToExcelMemberDataQuery',
// //   success(res){
// //     self.downloadStatus = false
// //   },
// //   error(err){
// //     self.downloadStatus = false
// //   }
// // })

// /**
//  *
//  *  AJAX 获取文件流
//  *  discretion 是否返回到回调函数上自行处理
//  * onDownloadProgress 下载进度
//  * @param {*} [opts={}]
//  * @returns
//  */
// function RequestFile(opts = {}) {
//   if (!opts.path || opts.path.trim() === '') {
//     if (process.env.NODE_ENV !== 'production') {
//       throw new Error('下载地址为空');
//     } else {
//       console.error('下载地址为空');
//       return false;
//     }
//   }
//   const isPMS = getOptions().isPMS ? 1 : 0;
//   let isLoadingPlugin = true;
//   if (opts.isLoadingPlugin === false) isLoadingPlugin = false;

//   const config = {
//     ...opts,
//     url: `/api/${opts.path}`,
//     method: opts.method || 'post',
//     params: opts.params || {},
//     data: opts.data || {},
//     responseType: 'arraybuffer',
//     timeout: DownloadFileTimeOut,
//     headers: {
//       isPMS,
//       filename: 'utf-8',
//     },
//   };

//   // if (isLoadingPlugin) operateLoading(true, '正在导出文件');

//   axios(config)
//     .then((res) => {
//       // if (isLoadingPlugin) operateLoading(false);
//       try {
//         // 通用处理 -- 其实判断不到.. 流拿不到 res.data.retcodoe
//         if (res.data.retcode && parseInt(res.data.retcode) === -41) {
//           window.location.href = '/#/loginPage';
//         } else if (opts.discretion && opts.success) {
//           opts.success.call(this, res.data);
//         } else {
//           const fname = getFileName(res.headers && res.headers['content-disposition']);
//           const blob = new Blob([res.data], {
//             type: opts.resContentType || 'application/vnd.ms-excel;charset=utf-8',
//           });
//           const downloadUrl = URL.createObjectURL(blob);
//           urlToDownload(downloadUrl, fname);
//           if (opts.success) opts.success.call(this);
//         }
//       } catch (error) {
//         throw new Error(`download file error:${error ? error.message : ''}`);
//       }
//     })
//     .catch((err) => {
//       // if (isLoadingPlugin) operateLoading(false);
//       if (opts.error) opts.error.call(this, err);
//     });
// }

// // 根据返回流下载文件
// function urlToDownload(downloadUrl = '', fname = '') {
//   if (!downloadUrl || downloadUrl.trim() === '') {
//     if (process.env.NODE_ENV !== 'production') {
//       throw new Error('下载地址或文件名为空');
//     } else {
//       console.error('下载地址或文件名为空');
//     }
//   }
//   const $ela = document.createElement('a');
//   $ela.setAttribute('href', `${downloadUrl}`);
//   if (fname && fname.trim() !== '') {
//     $ela.setAttribute('download', fname);
//   }
//   $ela.click();
//   // window.open(downloadUrl)
// }

// // 提取文件名
// function getFileName(str) {
//   const isMatch = str.match(/filename\s*=(.+['.xls'|'.xlsx'])/ig);
//   let name = 'member_export' + '.xlsx';
//   if (isMatch && Array.isArray(isMatch) && isMatch[0]) {
//     const _name = isMatch[0].replace(/[;|\s*|'=']/ig, '');
//     name = _name.replace('filename', '');
//   }
//   return name;
// }

// export default {
//   fetch,
//   showToast,
//   download: RequestFile,
//   urlToDownload,
// };
const {
  fetch,
  download,
  urlToDownload,
  showToast,
} = window.$axios;
export default {
  showToast,
  fetch,
  download,
  urlToDownload,
};

const hotelRouter = {
  path: '/lcproduct',
  component: () => import(/* webpackChunkName: "lcproduct-view-index" */ '../views/index.vue'),
  children: [
    // 酒店管理
    {
      path: 'hotelManagement',
      name: 'hotelManagement',
      meta: {
        permissionCode: 'cp-hotelManage',
        routerType: 3,
      },
      component: () => import('../views/hotel-management/hotel-management/hotel-management.vue'),
    },
    {
      path: 'hotelFacilities',
      name: 'hotelFacilities',
      meta: {
        permissionCode: 'cp-hotelManage',
        routerType: 3,
      },
      component: () => import('../views/hotel-management/hotel-management/hotel-facilities.vue'),
    },
    {
      path: 'contact',
      name: 'contact',
      meta: {
        permissionCode: 'cp-hotelManage',
        routerType: 3,
      },
      component: () => import('../views/hotel-management/hotel-management/contact.vue'),

    },
    // 酒店商城

    {
      path: 'setPromotion',
      name: 'setPromotion',
      meta: {
        permissionCode: 'cp-hotel-shoppingActivityConfig',
        routerType: 3,
      },

      component: () => import('../views/hotel-management/set-promotion/set-promotion.vue'),
    },
    {
      path: 'creatPromotion',
      name: 'creatPromotion',
      meta: {
        permissionCode: 'cp-hotel-shoppingActivityConfig',
        routerType: 3,
      },

      component: () => import('../views/hotel-management/set-promotion/createpromotion.vue'),
    },
    {
      path: 'managePromotion',
      name: 'managePromotion',
      meta: {
        permissionCode: 'cp-hotel-shoppingActivityConfig',
        routerType: 3,
      },

      component: () => import('../views/hotel-management/set-promotion/manage-promotion.vue'),
    },
    {
      path: 'addCombinationProduct',
      name: 'addCombinationProduct',
      meta: {
        permissionCode: 'cp-hotel-shoppingActivityConfig',
        routerType: 3,
      },

      component: () => import('../views/hotel-management/set-promotion/add-combination-product.vue'),
    },
    {
      path: 'creatCombinationProduct',
      name: 'creatCombinationProduct',
      meta: {
        permissionCode: 'cp-hotel-shoppingActivityConfig',
        routerType: 3,
      },

      component: () => import('../views/hotel-management/set-promotion/create-combination-product.vue'),
    },
    {
      path: 'addChileProduct',
      name: 'addChileProduct',
      meta: {
        permissionCode: 'cp-hotel-shoppingActivityConfig',
        routerType: 3,
      },

      component: () => import('../views/hotel-management/set-promotion/add-chile-product.vue'),
    },
    {
      path: 'promotionHistory',
      name: 'promotionHistory',
      meta: {
        permissionCode: 'cp-hotel-shoppingActivityConfig',
        routerType: 3,
      },

      component: () => import('../views/hotel-management/set-promotion/promotion-history.vue'),
    },

    // 商城订单

    {
      path: 'shoppingMallOrder',
      name: 'shoppingMallOrder',
      meta: {
        h_authCode: 'SCDD',
        permissionCode: 'cp-hotel-shoppingOrder',
        routerType: 3,
      },
      component: () => import('../views/hotel-management/mall-order/not-room-order.vue'),

    },
    {
      path: 'mallOrderDetail',
      name: 'mallOrderDetail',
      meta: {
        permissionCode: 'cp-hotel-shoppingOrder',
        routerType: 3,
      },
      component: () => import('../views/hotel-management/mall-order/mall-order-detail.vue'),
    },

    {
      path: 'detailPromotion',
      name: 'detailPromotion',
      meta: {
        permissionCode: 'cp-hotel-shoppingActivityConfig',
        routerType: 3,
      },
      component: () => import('../views/hotel-management/set-promotion/detail-Promotion.vue'),
    },
    {
      path: 'editPromotion',
      name: 'editPromotion',
      meta: {
        permissionCode: 'cp-hotel-shoppingActivityConfig',
        routerType: 3,
      },
      component: () => import('../views/hotel-management/set-promotion/edit-promotion.vue'),
    },
    {
      // 酒店端结算中心未入账账单跳到商城订单详情
      path: 'settlement-order/noAccountBill/mallOrderDetail',
      name: 'settleMallOrderDetail',
      meta: {
        permissionCode: 'cp-settlementCenter',
        routerType: 3,
      },
      component: () => import('../views/hotel-management/mall-order/mall-order-detail.vue'),
    },
    {
      // 酒店端结算中心已入账账单跳到商城订单详情
      path: 'settlement-order/accountBillDetail/mallOrderDetail',
      name: 'settleMallOrderDetail',
      meta: {
        permissionCode: 'cp-settlementCenter',
        routerType: 3,
      },
      component: () => import('../views/hotel-management/mall-order/mall-order-detail.vue'),
    },
  ],
};
export default hotelRouter;

import CurInfo from './routes'
import { getKey } from './utils'

export default (bus, store, moduleName, keyName) => {
  if (store) {
    store.registerModule(moduleName, {
      state: {
        routes: CurInfo.Routes
      },
      mutations: {
        'navigation/FORWARD': (state, { to, from, name }) => {
          state.routes.push(name)
        },
        'navigation/BACK': (state, { to, from, count }) => {
          state.routes.splice(state.routes.length - count, count)
        },
        'navigation/REPLACE': (state, { to, from, name }) => {
          state.routes.splice(CurInfo.Routes.length - 1, 1, name)
        },
        'navigation/REFRESH': (state, { to, from }) => {
        },
        'navigation/RESET': (state) => {
          state.routes.splice(0, state.routes.length)
        },
        'navigation/removeRoutes':(state) => {
          state.routes.splice(0, state.routes.length)
        },
      }
    })
  }

  const forward = (name, toRoute, fromRoute) => {
    const to = { route: toRoute }
    const from = { route: fromRoute }
    const routes = store ? store.state[moduleName].routes : CurInfo.Routes
    // if from does not exist, it will be set null
    from.name = routes[routes.length - 1] || null
    to.name = name
    store ? store.commit('navigation/FORWARD', { to, from, name }) : routes.push(name)
    window.sessionStorage[CurInfo.CUR_STORAGE_KEY] = JSON.stringify(routes)
    bus.$emit('forward', to, from)
  }
  const back = (count, toRoute, fromRoute) => {
    const to = { route: toRoute }
    const from = { route: fromRoute }
    const routes = store ? store.state[moduleName].routes : CurInfo.Routes
    from.name = routes[routes.length - 1]
    to.name = routes[routes.length - 1 - count]
    store ? store.commit('navigation/BACK', { to, from, count }) : routes.splice(CurInfo.Routes.length - count, count)
    window.sessionStorage[CurInfo.CUR_STORAGE_KEY] = JSON.stringify(routes)
    bus.$emit('back', to, from)
  }
  const replace = (name, toRoute, fromRoute) => {
    const to = { route: toRoute }
    const from = { route: fromRoute }
    const routes = store ? store.state[moduleName].routes : CurInfo.Routes
    // if from does not exist, it will be set null
    from.name = routes[routes.length - 1] || null
    to.name = name
    store ? store.commit('navigation/REPLACE', { to, from, name }) : routes.splice(CurInfo.Routes.length - 1, 1, name)
    window.sessionStorage[CurInfo.CUR_STORAGE_KEY] = JSON.stringify(routes)
    bus.$emit('replace', to, from)
  }
  const refresh = (toRoute, fromRoute) => {
    const to = { route: toRoute }
    const from = { route: fromRoute }
    const routes = store ? store.state[moduleName].routes : CurInfo.Routes
    to.name = from.name = routes[routes.length - 1]
    store ? store.commit('navigation/REFRESH', { to, from }) : null
    bus.$emit('refresh', to, from)
  }
  const reset = () => {
    console.log('===resetProduct')
    // console.dir(CurInfo)
    store ? store.commit('navigation/RESET') : CurInfo.Routes.splice(0, CurInfo.Routes.length)
    window.sessionStorage[CurInfo.CUR_STORAGE_KEY] = JSON.stringify([])
    bus.$emit('reset')
  }

  const updateRoutes = (routes) => {
    store ? store.commit('navigation/updateRoutes') : (CurInfo.Routes = [...routes]);
    window.sessionStorage[CurInfo.CUR_STORAGE_KEY] = JSON.stringify(routes);
  }

  const record = (toRoute, fromRoute, replaceFlag) => {
    const name = getKey(toRoute, keyName)
    if (replaceFlag) {
      replace(name, toRoute, fromRoute)
    } else {
      const toIndex = CurInfo.Routes.lastIndexOf(name)
      if (toIndex === -1) {
        forward(name, toRoute, fromRoute)
      } else if (toIndex === CurInfo.Routes.length - 1) {
        refresh(toRoute, fromRoute)
      } else {
        back(CurInfo.Routes.length - 1 - toIndex, toRoute, fromRoute)
      }
    }
  }

  return {
    record, reset, updateRoutes
  }
}
